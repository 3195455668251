
.respon {
  text-align: center;
  position: relative;
  border-bottom: 0;
  padding-bottom: 0;
  font-size: 1.3vw;
  height: 10vh;
}

#techList {
  position: relative;
  list-style: none;
  font-family: 'Oswald', sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  color: #EBEBEB;
  margin-top: 0.5rem;
  padding-left: 150px;
}

#techList li {
  position: absolute;
}

.python {
  font-weight: 600;
  letter-spacing: -.05em;
  -webkit-transform: scale(7.3, 7.8) translate(1.38em, 0.68em);
  -moz-transform: scale(7.3, 7.8) translate(1.38em, 0.68em);
}

.pandas {
  color: #5a7c9e;
  letter-spacing: .01em;
  font-weight: 700;
  -webkit-transform: scale(6.8, 5.55) rotate(90deg) translate(2.5em, -5.84em);
  -moz-transform: scale(6.8, 5.55) rotate(90deg) translate(2.5em, -5.84em);
}

.javascript {
  color: #5a7c9e;
  letter-spacing: -.04em;
  font-weight: 400;
  -webkit-transform: scale(1.95) rotate(90deg) translate(4em, -12.45em);
  -moz-transform: scale(1.95) rotate(90deg) translate(4em, -12.45em);
}

.responsive {
  color: #fff;
  font-weight: 300;
  letter-spacing: -.03em;
  word-spacing: 0.3em;
  -webkit-transform: scale(2.15) translate(1.95em, 4.7em);
  -moz-transform: scale(2.15) translate(1.95em, 4.7em);
}

.data_vis {
  color: #5a7c9e;
  font-weight: 500;
  letter-spacing: -.090em;
  -webkit-transform: scale(2.25, 1.9) translate(1.5em, 6.45em);
  -moz-transform: scale(2.25, 1.9) translate(1.5em, 6.45em);
}

.matplotlib {
  color: #A4A29D;
  font-weight: 400;
  letter-spacing: -0.05em;
  -webkit-transform: scale(2.70, 3) translate(1.95em, 4.9em);
  -moz-transform: scale(2.70, 3) translate(1.95em, 4.9em);
}

.datawarehouse {
  font-weight: 700;
  letter-spacing: -0.07em;
  word-spacing: 0.2em;
  -webkit-transform: scale(2) translate(1.1em, 10.7em);
  -moz-transform: scale(2) translate(1.1em, 10.7em);
}

.ia {
  color: #A4A29D;
  font-weight: 400;
  letter-spacing: -0.07em;
  -webkit-transform: scale(5.7, 4.0) translate(5.2em, 0.95em);
  -moz-transform: scale(5.7, 4.0) translate(5.2em, 0.95em);
}

.css3 {
  color: #ee5d29;
  font-weight: 700;
  letter-spacing: -0.07em;
  -webkit-transform: scale(2.8) translate(10.7em, 2.74em);
  -moz-transform: scale(2.8) translate(10.7em, 2.74em);
}

.machinelearning {
  color: #fff;
  letter-spacing: -.05em;
  font-weight: 300;
  -webkit-transform: scale(1.84, 1.95) rotate(90deg) translate(5.87em, -18.07em);
  -moz-transform: scale(1.84, 1.95) rotate(90deg) translate(5.87em, -18.07em);
}


.deep_learning {
  font-weight: 500;
  letter-spacing: 0.06em;
  -webkit-transform: scale(3.3, 4.35) translate(7.8em, 5.55em);
  -moz-transform: scale(3.3, 4.35) translate(7.8em, 5.55em);
}

.scikit {
  color: #A4A29D;
  font-weight: 700; 
  letter-spacing: 0.3em;
  -webkit-transform: scale(2.15, 2.50) translate(1.80em, 9.95em);
  -moz-transform: scale(2.15, 2.50) translate(1.80em, 9.95em);
}

.postgres {
  color: #fff;
  letter-spacing: -0.01em;
  font-weight: 400;
  -webkit-transform: scale(1, 1.42) rotate(90deg) translate(13.4em, -17.39em);
  -moz-transform: scale(1, 1.42) rotate(90deg) translate(13.4em, -17.39em);
}

.data_science{
  color: #5a7c9e;
  font-weight: 700;
  letter-spacing: -0.1em;
  -webkit-transform: scale(2.00, 1.20) translate(15.0em, 17.55em);
  -moz-transform: scale(2.00, 1.30) translate(14.85em, 16.90em);
}

.statistic_analysis{
  color: #ee5d29;
  letter-spacing: 0.05em;
  font-weight: 700;
  -webkit-transform: scale(1.64, 2.1) rotate(90deg) translate(6.7em, -18.57em);
  -moz-transform: scale(1.64, 2.1) rotate(90deg) translate(6.7em, -18.57em);
  z-index: -1;

}

.skills {
  color: #ee5d29;
  font-weight: 700;
  letter-spacing: -.03em;
  -webkit-transform: scale(4) translate(4.4em, 5em);
  -moz-transform: scale(4) translate(4.4em, 5em);
}

.pnl {
  color: #5a7c9e;
  letter-spacing: -0.01em;
  font-weight: 700;
  -webkit-transform: scale(2.05, 2.1) rotate(90deg) translate(9.5em, -12.1em);
  -moz-transform: scale(2.05, 2.1) rotate(90deg) translate(9.5em, -12.1em);
}

.data {
  color: #A4A29D;
  font-weight: 400;
  letter-spacing: -0.1em;
  -webkit-transform: scale(3.40, 4.20) translate(8.55em, 4.75em);
  -moz-transform: scale(3.40, 4.20) translate(8.55em, 4.75em);
}

.numpy {
  margin-left: 29px;
  font-weight: 300;
  letter-spacing: 0em;
  word-spacing: 0.1em;
  -webkit-transform: scale(2) translate(14.2em, 8.5em);
  -moz-transform: scale(2) translate(14.2em, 8.5em);
}

.tensorflow {
  color: #A4A29D;
  font-weight: 400;
  letter-spacing: -.04em;
  -webkit-transform: scale(3.40, 3.30) translate(7.05em, 3.23em);
  -moz-transform: scale(3.40, 3.30) translate(7.05em, 3.25em);
}

.ajax {
  color: #fff;
  letter-spacing: -0.001em;
  font-weight: 700;
  -webkit-transform: scale(2.65, 2.35) rotate(90deg) translate(5.80em, -5.85em);
  -moz-transform: scale(2.65, 2.35) rotate(90deg) translate(5.80em, -5.85em);
}


.jquery {
  letter-spacing: 0.095em;
  font-weight: 700;
  -webkit-transform: scale(4, 3.8) translate(6.1em, 3.72em);
  -moz-transform: scale(4, 3.8) translate(6.1em, 3.72em);
}


.php {
  color: #5a7c9e;
  letter-spacing: -0.01em;
  font-weight: 700;
  -webkit-transform: scale(3.4, 1.95) rotate(90deg) translate(7.7em, 0.49em);
  -moz-transform: scale(3.4, 1.95) rotate(90deg) translate(7.7em, 0.49em);
}

.mysql {
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.001em;
  -webkit-transform: scale(3.25, 3.95) translate(0.55em, 4.75em);
  -moz-transform: scale(3.25, 3.95) translate(0.55em, 4.75em);
}

.project {
  color: #5a7c9e;
  font-weight: 300;
  letter-spacing: -.03em;
  word-spacing: 0em;
  -webkit-transform: scale(1.67) translate(7.47em, 10.23em);
  -moz-transform: scale(1.67) translate(7.47em, 10.23em);
}

.server {
  color: #5a7c9e;
  -webkit-transform: scale(1.1) translate(-1.2em, 20.90em);
  -moz-transform: scale(1.2) translate(-0.5em, 19.30em);
}

.animation {
  font-weight: 500;
  letter-spacing: -0.1em;
  margin-left: 10px;
  -webkit-transform: scale(3, 4.35) translate(8.70em, 5.55em);
  -moz-transform: scale(3, 4.35) translate(8.70em, 5.55em);
}

.pixelperfect {
  color: #A4A29D;
  font-weight: 700;
  margin-left: -120px;
  letter-spacing: 0.3em;
  -webkit-transform: scale(2.15, 2.50) translate(2.95em, 9.95em);
  -moz-transform: scale(2.15, 2.50) translate(2.95em, 9.95em);
}

.pytorch {
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.1em;
  -webkit-transform: scale(2.00, 1.20) translate(17.2em, 16.55em);
  -moz-transform: scale(2.00, 1.20) translate(17.2em, 16.55em);
}

.java {
  color: #A4A29D;
  font-weight: 700;
  margin-left: -85px;
  letter-spacing: 0.20em;
  -webkit-transform: scale(1.05, 1.50) translate(10.50em, 12.60em);
  -moz-transform: scale(1.05, 1.50) translate(10.50em, 12.60em);
}


@media screen and (max-width: 960px) {
#techList{
  padding-left: 7%;
}


}