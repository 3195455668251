.btn {
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #FFF6E6;
  cursor: pointer;
  background-color: #7ECEFC;
}

.btn:hover {
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #FFF6E6;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}

 .mail{
  font-size: 18px;
 }



 .contact_me{
  width:max-content;
  margin-right: 20px;
 }