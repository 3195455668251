h1 {
  text-align: center;
  color: #353b48;
}

h3 {
  margin: 5px;
  color: #353b48;
}
.chart_area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  background-color: #d1efff;
  height: 45vh;
}

.title_about {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #a9a9a9;
  font-size: 3rem;
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #b0c4de;
}

.radar {
  width: 22%;

}

.all_skill {
  display: flex;
}

.aboutmeImg {
  border-radius: 10px;
  height: 100%;
}

.web_skill,
.data_science_lenguage,
.python_data_science_libraries {
  flex-wrap: wrap;
  width: 30vw;
  margin: 30px auto;
  color: #fff;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 2%;
}

.web_skill li {
  margin: 20px 0;
  padding: 10px;
}

.data_science_lenguage li {
  margin: 20px 0;
  padding: 10px;
}

.python_data_science_libraries li {
  margin: 20px 0;
  padding: 10px;
}

.bar {
  background: #3f464d;
  display: block;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.24);
}

.bar:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.bar span {
  height: 20px;
  float: left;
  background: linear-gradient(to right, #7ecefc, #2186c4);
}

.bar span:hover {
  cursor: pointer;
}

.css_bar {
  width: 80%;
  animation: css 3s;
}

.javascript_bar {
  width: 80%;
  animation: javascript 3s;
}

.php_bar {
  width: 80%;
  animation: php 3s;
}

.java_bar {
  width: 50%;
  animation: java 3s;
}

.laravel_bar {
  width: 80%;
  animation: laravel 3s;
}

.react_bar {
  width: 60%;
  animation: react 3s;
}

.python_bar {
  width: 90%;
  animation: python 3s;
}

.fast_bar {
  width: 80%;
  animation: fast 3s;
}

.django_bar {
  width: 60%;
  animation: django 3s;
}

.r_bar {
  width: 70%;
  animation: r 3s;
}

.sql_bar {
  width: 90%;
  animation: sql 3s;
}

.streamlit_bar {
  width: 95%;
  animation: streamlit 3s;
}

.dax_bar {
  width: 65%;
  animation: dax 3s;
}

.dash_bar {
  width: 65%;
  animation: dash 3s;
}

.julia_bar {
  width: 40%;
  animation: julia 3s;
}

.numpy_bar {
  width: 85%;
  animation: numpy 3s;
}

.jupyter_bar {
  width: 90%;
  animation: jupyter 3s;
}

.pandas_bar {
  width: 90%;
  animation: pandas 3s;
}

.pytorch_bar {
  width: 65%;
  animation: pytorch 3s;
}

.keras_bar {
  width: 75%;
  animation: keras 3s;
}

.power_bar {
  width: 75%;
  animation: power 3s;
}

.altair_bar {
  width: 90%;
  animation: altair 3s;
}

.scikit_learn_bar {
  width: 70%;
  animation: scikit 3s;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100vh;
  align-items: center;
}

.progress {
  width: 200px;
  height: 200px;
  font-size: 30px;
  color: #fff6e6;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #3f464d;
  text-align: center;
  line-height: 200px;
  margin: 20px;
}

.progress::after {
  content: "%";
}

.progress .title {
  position: relative;
  z-index: 100;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #3f464d;
}

.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 10px solid #3f464d;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}


#Barchart{
  width: 100%;
  height: 100%;
}

.progress .left {
  animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
  animation: load2 0.5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
  animation: load3 0.8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }
  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}

@keyframes html {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes css {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

@keyframes javascript {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

@keyframes php {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

@keyframes java {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes laravel {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

@keyframes python {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes streamlit {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

@keyframes dax {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}

@keyframes dash {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}

@keyframes react {
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
}

@keyframes fast {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

@keyframes django {
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
}

@keyframes r {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}

@keyframes sql {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes julia {
  0% {
    width: 0%;
  }
  100% {
    width: 40%;
  }
}

@keyframes jupyter {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes pandas {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes numpy {
  0% {
    width: 0%;
  }
  100% {
    width: 85%;
  }
}

@keyframes keras {
  0% {
    width: 0%;
  }
  100% {
    width: 75%;
  }
}

@keyframes scikit {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}

@keyframes pytorch {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}

@keyframes altair {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}

@keyframes power {
  0% {
    width: 0%;
  }
  100% {
    width: 75%;
  }
}

@media screen and (max-width: 960px) {
  .title_card {
    font-size: 1rem;
  }

  .card_skill h3 {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 430px) {
  .radar {
    width: 100%;
    margin-top:20px;
  }
}
