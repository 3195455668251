$blue: #2186C4;

.btn {
  border-color: $blue;
  color: #fff;
  box-shadow: 0 0 40px 40px $blue inset, 0 0 0 0 $blue;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  font-size: 18px;
  padding: 8px 20px;
  outline: none;
  border: none;
  border-radius: 4px;
  
  
  &:hover {
    box-shadow: 0 0 10px 0 $blue inset, 0 0 10px 4px $blue;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    color:$blue;
}
}