
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: sans-serif;
  font-weight: bold;
}

button,
input {
  font-family: sans-serif;
   font-weight: bold;
}

.container_blog {
  max-width: auto;
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
  color: #ffff;
}
