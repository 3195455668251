.navbar {
  background: black;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  
}

.navbar-logo {
  color: #FFF6E6;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.3rem;
  width: 100%;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: #FFF6E6;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.iniziale{
  color: #2186C4 ;
}


.nav-links:hover  .iniziale{
  color:#FF8066 ;
}


.nav-links:hover {
  background-color: #2186C4;
  border-radius: 5%;
  transition: all 0.4s ease-out;
}


.fa-bars {
  color: #FFF6E6;
}

.nav-links-mobile {
  display: none;
}

.drake, .home, .Me{
  font-size: 20px;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1150px) {
  .NavbarItems {
    position: relative;
  }



  .Box{
    margin-left: 25.5%;
  }


  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: fixed;
    top: 8vh;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 0.95;
    transition: all 0.5s ease;
    z-index: 10 !important;
    height: 92vh;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }


  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    width: max-content;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #FFF6E6;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #FFF6E6;
    font-size: 1.5rem;
    z-index: 10 !important;
  }

  .nav-links-mobile:hover {
    background: #FFF6E6;
    color: #1888ff;
    transition: 250ms;
  }

  .btn {
    display: none;
  }

}