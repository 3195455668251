.boxes{
    margin: 20px;
    margin-top: 50px;
    background-color: #D1EFFF;
    border-radius: 10px;
    overflow: hidden;
    height:350px;
    max-height: 35vh;
    box-shadow: 0px 12px 18px -6px rgba(0,0,0,0.3);
}

.all_skill li h3{
    color: white;
}

.grids{
    position: relative;
    top: 115vh;
    max-width: 90%;
    margin: auto
}

.bar_chart{
    width: 60vh;
}

.grafico{
    width: 100%;
}

.grids:last-child{
    padding-bottom: 150px;
}

@media screen and (max-width: 960px) {
    
    .grids{
        top:29vh;
        display: hidden;
    }
    .box-image{
        opacity: 0.4;
        z-index: -1;
    }
    .softSkillList{
        opacity: 1;
        font-size: 15px !important;
        font-weight: bold !important;
        left: 4% ! important;
    }
    .box1, .box2, .box3{
    padding: 0px !important;
    }
    .chart_area{
        display: grid;
        height: 100%;
        width: 100%;
    }
    .bar_chart{
        opacity: 0.45;
    }

}


@media screen and (max-width: 700px) and (min-width: 400px) {
    .softSkillList{
        top: 4% !important;
        text-align: center;
    }

    .boxes ul li{
        margin-top: 24.6px !important;
    }

}












@media screen and (max-width: 400px) {

    .chart_area{
        display: grid;
        height: 100%;
        width: 100%;
    }

    .softSkillList{
        top: 9% !important;
    }


    .boxes ul li{
        margin-top: 14.6px !important;
    }

}










.box1, .box2, .box3{
    display: flex;
    padding: 20px;
}

.softSkillList{
    position: absolute;
    right: 0;
    top: 10px;
    left: 40%;
    font-size: 27px;
    top:20%;
    padding-bottom: 40px;
}

.checktick {
    color:green;
    background: white;
    cursor:pointer;
}

.title_card{
    color: white;
}