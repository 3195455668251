.divider{
    position: absolute;
    left: 50%;
    background-color:#FFF6E6;
    width: 4px;
    height: 600px;
}

.contenitore_mail{
    display: flex;
}

.titleMail{
    margin-top: 20px;
    margin-bottom: 40px;
    color: #a9a9a9;
    font-size: 3rem;
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #b0c4de;
}

form{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-bottom: 40px;
    width: 500px;

}

input, textarea{
    border-radius: 7px;
    width: 500px;
    height: 60px;
    background-color: #FFF6E6;
    margin-left: 150px;
    margin-top: 5px;
    font-size: 20px;
    padding-left: 10px;
}

textarea{
    height:300px;
    padding: 8px;
    resize: none;
}

.modulo_tratamento_dati{
   display: hidden !important;
   flex-direction: row !important;
    margin-bottom: 80px;
}

.acconsento{
    position:relative;
    left: 190px;
    top: 5px;
    font-weight: bold;
    display: inline-block;
}

.trattamentoDati{
    position:absolute;
    height: 6%;
    width: 20px;
    left: 440px;
    
}

.btn_mail{
    margin-top: 50px;
    background-color:#FF8066;
    color: #FFF6E6;
}

.btn_mail:hover{
    cursor: pointer;
    box-shadow: 0 0 41px #FF8066;
    color: #2186C4; 
}

.acconsento{
    color: white;
}

.blogItem-wrap h3{
    color: white;
}

.blogItem-author h6{
    color: white;
}

.blogItem-link{
    color: white;
    font-size: 30px;
}

.blogItem-link:hover{
    color: #2186C4;
}


@media screen and (max-width: 1450px) {

    .divider{
        display: none;
    }

}





@media screen and (max-width: 960px) {


    .contenitore_mail{

        flex-direction: column-reverse;

    }
    
    input, textarea{
        border-radius: 7px;
        width: 320px;
        height: 60px;
        background-color:#FFF6E6;
        margin-left: 20px;
        margin-top: 5px;
        font-size: 20px;
        padding-left: 10px;
    }
    
    .acconsento{
        left: 2px;
        font-size: 13px;
        width:360  !important;
    }
    
    .modulo_trattamento_dato{
        width: 360px;
    }
    
    .typewriter{
        top: 1px;
        left: 100px;
    }

    .trattamentoDati{
        left: 300px;
    }

    .typrwwriter{
        margin-top: 200px !important;
    }

    form{
        width: 200px;
    }

    textarea{
        height:160px;
        padding: 8px;
    }
    
    .acconsento {
        width: 100% !important;
        padding-left: 20px;
    }

    .modulo_trattamento_dato {
            width: 100% !important;
    }

    form {
        margin-top: 20px;
        padding-left: 0;
    }
}



@media screen and (max-width: 430px) {

    form{
        margin-left: 8%;
    }

    .acconsento {
            width: 100% !important;
        }

    .modulo_trattamento_dato {
            width: 100% !important;
    }

    form {
        margin-top: 20px;
        padding-left: 0;
    }
}
    