* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Varela Round', sans-serif;
}

body{
  background:#112837;
}

li{
  list-style: none;
}

ul{
  list-style-type:none;
}

.nomi {
  color: #FFF6E6;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  font-size: 3vw;
}

::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@keyframes html{
    0%{
        width: 0%;
    }
    100%{
        width: 90%;
    }
}

.github {
  fill: #FFF6E6;
  transition: fill 0.3s, transform 0.3s;
  /* Aggiungi una transizione per la transizione fluida */
}

.github:hover {
  fill: blueviolet;
  transform: scale(1.2);
  /* Ingrandisci l'icona al passaggio del mouse */
}

.linkedin {
  fill: #FFF6E6;
  transition: fill 0.3s, transform 0.3s;
}

.linkedin:hover {
  fill: dodgerblue;
  transform: scale(1.2);
}

.youtube {
  fill: #FFF6E6;
  transition: fill 0.3s, transform 0.3s;
}

.youtube:hover {
  fill: red;
  transform: scale(1.2);
}

.kaggle {
  fill: #FFF6E6;
  transition: fill 0.3s, transform 0.3s;
}

.kaggle:hover {
  fill: aqua;
  transform: scale(1.2);
}


.streamlit {
  fill: #FFF6E6;
  transition: fill 0.3s, transform 0.3s;
}

.streamlit:hover {
  fill: #ff4b4b;
  transform: scale(1.2);
}





.home {
  height: 110vh;
}

.home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFF6E6;
  font-size: 100px;
}



.contact-us {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #FFF6E6;
  font-size: 100px;
}

.container{
  width: 1000px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 70px;
}

.container .card{
  position: relative;
  cursor: pointer;
  flex: 20%;
}

.container .card .face{
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container .card .face.face1{
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container .card:hover .face.face1{
  background: #f5823e;
  transform: translateY(0);
}

.container .card .face.face1 .content{
  opacity: 0.2;
  transition: 0.5s;
}

.container .card:hover .face.face1 .content{
  opacity: 1;
}

.container .card .face.face1 .content img{
  max-width: 100px;
}

.container .card .face.face1 .content h3{
  margin: 10px 0 0;
  padding: 0;
  color: #FFF6E6;
  text-align: center;
  font-size: 1.5em;
}

.container .card .face.face2{
  position: relative;
  background: #FFF6E6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.container .card:hover .face.face2{
  transform: translateY(0);
}

.container .card .face.face2 .content p{
  margin: 0;
  padding: 0;
}

.container .card .face.face2 .content a{
  margin: 15px 0 0;
  display:  inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.container .card .face.face2 .content a:hover{
  background: #333;
  color: #FFF6E6;
}

@media screen and (max-width: 960px) {
  .nomi{
    position: fixed;
    top: 40%;
    left: 50%;
    font-size: 30px;
  }

  .container{
      padding-left: 25%;
  }

  .container .card{
    position: relative;
    cursor: pointer;
    flex: 100%;
  }

}

